import React from "react";
import logo from "../assets/application-logo.png";
import styled from "styled-components";

const RegisterContent = () => {
    return <Container>
        <LogoWrapper>
            <ImageWrapper>
                <img src={logo} alt=""/>
            </ImageWrapper>

            <h3>ご確認ありがとうございます。会員登録が完了しました。</h3>
            <h4>Thank you for your confirmation. Your membership registration has been completed.</h4>
        </LogoWrapper>
    </Container>
}

const ImageWrapper = styled.div`
  //max-width: 250px;
  margin-top: 100px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  img {
    max-width: 70%;
  }

  h3 {
    color: #ff8d8d;
    text-align: center;
    font-size: 22px;
  }

  h4 {
    color: #ff8d8d;
    text-align: center;
    font-size: 18px;
  }

  span {
    //color: #5dc399;
    font-weight: 300;
    font-size: 18px;
  }
`;

const Container = styled.div`
  width: 100vw;
  max-width: 400px;
  backdrop-filter: blur(35px);
  background-color: rgba(255, 255, 255, 0.8);
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  padding: 0 2rem;

  .hasBeenReset {
    margin-top: 50px;
  }

  @media (max-width: 900px) {
    width: 100vw;
    position: absolute;
    padding: 0;
  }

  h4 {
    color: #808080;
    font-weight: bold;
    font-size: 13px;
    margin-top: 2rem;

    span {
      color: #ff8d8d;
      cursor: pointer;
    }
  }
`;

export default RegisterContent;